export type ProductListAction = {
    type: string,
    data: ProductListActionData | null,
}

export type ProductListActionData = {
    objectId?: string | null,
    openFilter?: string | null,
    filterOffcanvasOpen?: boolean | null
    query?: string | null
    showMore?: boolean
    nameCategory?: string
    breadcrumb?: object[]
    categoryList?: object[]
    pathname?: string | null
    categoryUrl?: string | null
    categorySeoTitle?: string | null
    categorySeoDescription?: string | null
    categoryFacet?: object | null
}

export const setQuery = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_EDIT_QUERY',
    data: state,
})

export const setPathName = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_EDIT_PATH_NAME',
    data: state,
})

export const setOpenFilter = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_OPEN_FILTER',
    data: state,
})

export const setShowMore = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_SHOW_MORE',
    data: state,
})

export const setNameCategory = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_CATEGORY_NAME',
    data: state,
})

export const setBreadCrumb = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_BREADCRUMB',
    data: state,
})

export const setCategoryUrl = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_UPDATE_CATEGORY_URL',
    data: state,
})

export const setCategorySeo = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_UPDATE_CATEGORY_SEO',
    data: state,
})

export const setCategoryFacet = (state: ProductListActionData): ProductListAction => ({
    type: 'ACTION_UPDATE_CATEGORY_FACET',
    data: state,
})
