import React from 'react'
import PropTypes from 'prop-types'

import VideoTeaser from '../../patterns/organisms/VideoTeaser'

function VideosTeaserTastic({ data }) {
    return (
        <VideoTeaser
            text={data.text}
            tagHeadline={data.tagHeadline}
            video={data.videoList}
            headlineColor={data.headlineColor}
        />
    )
}

VideosTeaserTastic.propTypes = {
    data: PropTypes.shape({
        text: PropTypes.string,
        headlineColor: PropTypes.string,
        tagHeadline: PropTypes.string,
        videoList: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string,
            previewPic: PropTypes.object,
        })).isRequired,
    }),
}

export default VideosTeaserTastic
