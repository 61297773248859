import React from 'react'
import classnames from 'classnames'
import {
  FormattedMessage, injectIntl,
} from 'react-intl'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowUp } from '../../../../../icons/sport2000-icons/arrow-up.svg'
import { ReactComponent as ArrowDown } from '../../../../../icons/sport2000-icons/arrow-down.svg'

const FilterCategories = ({
  setSelectedCategoryOption,
  setFilteredCategory,
  setIsShowCategoryEmptyMsg,
  categoryOptions,
  selectedCategoryOption,
  isSearchPage,
  isOpen,
  setIsOpen,
  themeName,
  setIsChangeCategory,
  setSearchHitsOpen,
  setCurrentValue,
  initialCurrentUrlParam,
}) => {
  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleChangeCategory = (event, option) => {
    setSelectedCategoryOption(option)
    setFilteredCategory(option?.id || '')
    setIsShowCategoryEmptyMsg(false)
    setIsOpen(false)
    setIsChangeCategory(true)

    if (isSearchPage) {
      setSearchHitsOpen(true)
      setCurrentValue(initialCurrentUrlParam)
    }
  }

  return (
    <div className={classnames('dropdown-categories filter-panel-wrapper', {
      'is--open': isOpen,
      'is--search-page': isSearchPage,
    })}
    >
      <div className={'filter-panel-select'}>
        <button
          type={'button'}
          className={classnames('filter-buttons-toggle', {
            'is--open': isOpen,
            'theme--green': themeName === 'theme-absolute-teamsport',
            'theme--blue': themeName === 'theme-running-expert',
          })}
          onClick={() => { handleClick() }}
        >
          {selectedCategoryOption ? (
            <span>{selectedCategoryOption.name}</span>
          ) : (
            <FormattedMessage id={'search.searchBox.dropdownCategoryHeadline'} />
          )}

          <span className={'filter-buttons-toggle-arrow'}>
            {isOpen ? (<ArrowUp width={16} height={16} />) : (<ArrowDown width={16} height={16} />)}
          </span>
        </button>
      </div>
      {isOpen && (
        <div className={'dropdown-categories-wrap'}>
          <div className={'dropdown-categories-wrap-list'}>
            <div
              className={'dropdown-categories-wrap-list-item'}
              onClick={(e) => handleChangeCategory(e, null)}
              key={'category-all'}
            >
              <FormattedMessage id={'search.searchBox.dropdownCategoryHeadline'} />
            </div>
            {categoryOptions.map((option) => (
              <div
                className={'dropdown-categories-wrap-list-item'}
                onClick={(e) => handleChangeCategory(e, option)}
                key={option.key}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

FilterCategories.propTypes = {
  setSelectedCategoryOption: PropTypes.func,
  setFilteredCategory: PropTypes.func,
  setIsShowCategoryEmptyMsg: PropTypes.func,
  categoryOptions: PropTypes.array,
  isSearchPage: PropTypes.bool,
  themeName: PropTypes.string,
  setIsChangeCategory: PropTypes.func,
  selectedCategoryOption: PropTypes.any,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  setSearchHitsOpen: PropTypes.func,
  setCurrentValue: PropTypes.func,
  initialCurrentUrlParam: PropTypes.string,
}

export default injectIntl(FilterCategories)
