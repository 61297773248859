import { useRouter } from 'next/router';
import React, {
  Dispatch, SetStateAction, useEffect, useMemo, useRef, useState,
} from 'react'
import {
  FormattedMessage, WrappedComponentProps, injectIntl, IntlShape,
} from 'react-intl'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import IconButton from '../../atoms/button/IconButton'
import { ReactComponent as CloseIcon } from '../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as SearchIcon } from '../../../../icons/sport2000-icons/search.svg'
import { ReactComponent as LoadingIcon } from '../../../../icons/sport2000-icons/loading.svg'
import useDebounce from '../../../services/hook/useDebounce'
import FilterCategories from './Filter/FilterCategories'
import _debounce from 'lodash/debounce'
import _isArray from 'lodash/isArray'
import _remove from 'lodash/remove'
import _union from 'lodash/union'

export type Props = {
  intl: IntlShape
  setSearchHitsOpen: Dispatch<SetStateAction<boolean>>
  mobileSetCurrentActiveItem?: Dispatch<SetStateAction<string>>
  mobileToggleMenu?: Dispatch<SetStateAction<string>>
  mobileSearchMenuOpen?: boolean
  placeholderSearch?: string
  valueText?: string
  currentValue?: string
  setValueText: Dispatch<SetStateAction<string>>
  setCurrentValue: Dispatch<SetStateAction<string>>
  setTriggerClickInput?: any
  searchBrandUrl?: any
  searchHitsOpen?: boolean
  categories: any
  selectedCategoryOption: any
  setSelectedCategoryOption: any
  filteredSearchCategory: any
  setFilteredSearchCategory: any
  setIsShowCategoryEmptyMsg: any
  isSearchPage?: boolean
  node: any
  themeName?: string
  allSearchResults: any
  specialCategoryUrl?: string
  searching?: boolean
  filteredCategory: any
  setFilteredCategory: any
} & WrappedComponentProps

const ABSOLUTE_PATHNAME = '/absolute-teamsport'
const RUNNING_PATHNAME = '/running-experts'
const ALL_CATEGORY_PATHNAME = 'c/alle'
const RUNNING_CATEGORY = 'running'
const FUSSBALL_CATEGORY = 'fussball'
const SearchBox: React.FC<Props> = ({
  intl,
  setSearchHitsOpen,
  mobileSetCurrentActiveItem,
  mobileToggleMenu,
  mobileSearchMenuOpen,
  placeholderSearch,
  valueText,
  setValueText,
  setTriggerClickInput,
  searchBrandUrl,
  searchHitsOpen,
  isSearchPage,
  categories,
  selectedCategoryOption,
  setSelectedCategoryOption,
  filteredCategory,
  setFilteredCategory,
  setIsShowCategoryEmptyMsg,
  node,
  themeName,
  specialCategoryUrl,
  searching,
  currentValue,
  setCurrentValue,
}: Props) => {
  const router = useRouter();
  const currentUrlParams = router.query
  const [searchBoxOpen, setSearchBoxOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const isDesktop = useDeviceType() === 'desktop'
  const debounceSearch = useDebounce(currentValue, 500)
  const [initialCurrentUrlParam, setInitialCurrentUrlParam] = useState(`${currentUrlParams?.query || ''}`)
  const [isChangeCategory, setIsChangeCategory] = useState(false)
  const categoryOptions = useMemo(() => {
    if (categories && categories.length > 0) {
      const shopCategory = categories.filter((cat) => cat.key === 'Sport2000')
      if (shopCategory) {
        const sportCategory = shopCategory[0].children.filter((cat) => cat.key === 'Sportarten')
        return sportCategory[0]?.children || []
      }
    }
    return []
  }, [categories])

  useEffect(() => {
    preSelectOption()
  }, [node])

  const preSelectOption = () => {
    const fullPathname = router.asPath
    if (selectedCategoryOption || isChangeCategory) {
      return
    }

    if (currentUrlParams.categoryoption) {
      const selectedCategoryOption = categoryOptions.filter((cat) => cat.id === currentUrlParams.categoryoption)?.[0] || null
      setSelectedCategoryOption(selectedCategoryOption)
      return
    }

    const currentCatId = node?.configuration?.entity?.categoryId
    if (currentCatId) {
      const currentCat = categoryOptions.filter((cat) => cat.id === currentCatId)?.[0] || null
      if (currentCat) {
        setSelectedCategoryOption(currentCat)
        return
      }
    }

    const ancestors = node?.configuration?.entity?.projectSpecificData?.ancestors || []
    const parentCat = categoryOptions.filter((cat) => ancestors.indexOf(cat.id) > -1)?.[0] || null
    if (parentCat) {
      setSelectedCategoryOption(parentCat)
      return
    }

    const productCategoryIds = node?.configuration?.entity?.projectSpecificData?.categoryIds
    if (productCategoryIds) {
      const productCat = categoryOptions.filter((cat) => productCategoryIds.indexOf(cat.id) > -1)?.[0] || null
      if (productCat) {
        setSelectedCategoryOption(productCat)
        return
      }
    }

    if (fullPathname.includes(ABSOLUTE_PATHNAME)) {
      const currentCat = categoryOptions.filter((cat) => cat.key === 'Fussball')?.[0] || null
      if (currentCat) {
        setSelectedCategoryOption(currentCat)
      }
      return
    }

    if (fullPathname.includes(RUNNING_PATHNAME)) {
      const currentCat = categoryOptions.filter((cat) => cat.key === 'Running')?.[0] || null
      if (currentCat) {
        setSelectedCategoryOption(currentCat)
      }
    }
  }

  const selectedCategoryName = selectedCategoryOption && selectedCategoryOption.name && selectedCategoryOption.name.toLowerCase()

  const localStorageHandle = (event) => {
    const debounce = _debounce((event) => {
      if (event.target.value.trim() === '') {
        return
      }

      const arrayInput = localStorage.getItem('search') ? JSON.parse(localStorage.getItem('search')) : null

      if (!arrayInput) {
        localStorage.setItem('search', JSON.stringify([event.target.value.trim()]))
      }

      if ((_isArray(arrayInput)) && arrayInput.length) {
        _remove(arrayInput, (n) => n === event.target.value.trim())
        const newArray = _union(arrayInput, [event.target.value.trim()])

        localStorage.setItem('search', JSON.stringify(newArray))
      }
    }, 1000)

    debounce(event)
    event.persist()
  }

  const onTypeInSearchInput = () => {
    if (debounceSearch) {
      setIsShowCategoryEmptyMsg(false)
      setFilteredCategory(selectedCategoryOption?.id)
      if (!currentValue) {
        setTriggerClickInput(true)
        if (setSearchHitsOpen) {
          setSearchHitsOpen(true)
        }
        return
      }

      if (currentValue.trim() !== valueText) {
        setValueText(currentValue)
      }

      if (setSearchHitsOpen) {
        setSearchHitsOpen(true)
      }
    }
  }

  const onCloseSearch = () => {
    setCurrentValue('')
    setInitialCurrentUrlParam('')
    inputSearchRef.current?.blur()
    setSearchBoxOpen(false)
    if (setSearchHitsOpen) {
      setSearchHitsOpen(false)
    }
  }

  const onCloseSearchMobile = () => {
    onCloseSearch()

    if (mobileSetCurrentActiveItem) {
      mobileSetCurrentActiveItem('')
    }

    if (mobileToggleMenu) {
      mobileToggleMenu(null)
    }
  }

  const onClickSubmitButton = (e) => {
    e.preventDefault()
    if (searching) {
      return
    }

    setSearchHitsOpen?.(false)
    setSearchBoxOpen?.(false)

    setIsShowCategoryEmptyMsg(false)
    setFilteredCategory(selectedCategoryOption?.id)

    const filteredCategoryParams = selectedCategoryOption ? `&categoryfilter=${selectedCategoryOption?.id}&categoryoption=${selectedCategoryOption.id}&categorykey=${selectedCategoryOption.key}&categoryname=${selectedCategoryOption.name}` : ''

    const redirectUrlCategory = specialCategoryUrl || `/search/?query=${encodeURIComponent(inputSearchRef.current.value || '')}${filteredCategoryParams}`
    const redirectUrlBrand = searchBrandUrl && searchBrandUrl.length > 0 ? `/${searchBrandUrl}`
      : redirectUrlCategory

    let targetUrl = ''

    if (inputSearchRef.current.value) {
      targetUrl = (selectedCategoryName === RUNNING_CATEGORY || selectedCategoryName === FUSSBALL_CATEGORY)
        ? redirectUrlCategory : redirectUrlBrand
    } else {
      // Case when user click on search icon without typing anything
      if (selectedCategoryOption && selectedCategoryOption.key && selectedCategoryOption.url) {
        if (selectedCategoryOption.key === "Fussball") {
          targetUrl = ABSOLUTE_PATHNAME
        } else if (selectedCategoryOption.key === "Running") {
          targetUrl = RUNNING_PATHNAME
        }
        else {
          targetUrl = selectedCategoryOption.url
        }
      } else {
        // TODO: Change to default search page
        targetUrl = ALL_CATEGORY_PATHNAME
      }
    }

    window.location.href = targetUrl
  }

  const keyboardHandler = (event) => {
    if (event.key === 'Escape') {
      onCloseSearch()
    }
  }

  const bodyClickHandler = (event) => {
    if (event.target.closest('.dropdown-categories.filter-panel-wrapper')) {
      return
    }
    setIsOpen(false)

    if (event.target.closest('.search')) {
      return
    }
    onCloseSearch()
  }

  useEffect(() => {
    document.body.addEventListener('mousedown', bodyClickHandler)
    const searchBoxElement = document.querySelector('#search-box')
    searchBoxElement.addEventListener('keydown', keyboardHandler)
    document.addEventListener('onCloseSearch', isDesktop ? onCloseSearch : onCloseSearchMobile)
    // document.addEventListener('onCloseSearch', onCloseSearch)
    return () => {
      document.body.removeEventListener('mousedown', bodyClickHandler)
      searchBoxElement.removeEventListener('keydown', keyboardHandler)
      document.removeEventListener('onCloseSearch', isDesktop ? onCloseSearch : onCloseSearchMobile)
      // document.removeEventListener('onCloseSearch', onCloseSearch)
      clearTimeout(debounceSearch)
    }
  }, [])

  useEffect(() => {
    if (debounceSearch) {
      onTypeInSearchInput()
    }
  }, [debounceSearch])

  useEffect(() => {
    if (!isDesktop && mobileSearchMenuOpen) {
      inputSearchRef.current?.focus()
    }
  }, [mobileSearchMenuOpen])

  return (
    <div
      className={classnames('search search-box', {
        'is--open': (searchBoxOpen && !isSearchPage) || isSearchPage,
      })}
    >
      <form
        className={'search-box--form relative'}
        noValidate
        role={'search'}
      >
        {(searchHitsOpen || isSearchPage || searchBoxOpen) && (
          <FilterCategories
            categoryOptions={categoryOptions}
            setSelectedCategoryOption={setSelectedCategoryOption}
            setFilteredCategory={setFilteredCategory}
            setIsShowCategoryEmptyMsg={setIsShowCategoryEmptyMsg}
            selectedCategoryOption={selectedCategoryOption}
            // @ts-ignore
            isSearchPage={isSearchPage}
            // @ts-ignore
            setIsOpen={setIsOpen}
            // @ts-ignore
            isOpen={isOpen}
            // @ts-ignore
            themeName={themeName}
            // @ts-ignore
            setIsChangeCategory={setIsChangeCategory}
            // @ts-ignore
            initialCurrentUrlParam={initialCurrentUrlParam}
            // @ts-ignore
            setSearchHitsOpen={setSearchHitsOpen}
            // @ts-ignore
            setCurrentValue={setCurrentValue}
          />
        )}

        <div className={'input--wrapper'}>
          <input
            id={'search-box'}
            className={'search-box--input'}
            autoComplete={'off'}
            type={'search'}
            value={decodeURIComponent(currentValue || initialCurrentUrlParam)}
            onClick={() => {
              setSearchBoxOpen(true)
            }}
            onFocus={(event) => {
              setSearchBoxOpen(true)
              if (event.currentTarget.value === '') {
                setTriggerClickInput(true)
                if (setSearchHitsOpen) {
                  setSearchHitsOpen(true)
                }
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onClickSubmitButton(e)
                return
              }
              localStorageHandle(e)
            }}
            placeholder={isDesktop ? placeholderSearch : (
              intl.formatMessage({ id: 'search.searchBox.inputPlaceholderMobile' })
            )}
            ref={inputSearchRef}
            onChange={(e) => {
              setCurrentValue(e.target.value)
              setInitialCurrentUrlParam('')
            }}
          />
          <label htmlFor={'search-box'}>
            {isDesktop ? (
              placeholderSearch
            ) : (
              <FormattedMessage
                id={'search.searchBox.inputPlaceholderMobile'}
              />
            )}
          </label>
          <span className={'input--border'} />

          <IconButton
            id={'search-box--submit'}
            className={classnames('btn search-box--submit-icon', {
              'search-box--submit-icon-animation': searchHitsOpen && !isSearchPage,
            })}
            type={'button'}
            ariaLabel={intl.formatMessage({ id: 'search.searchBox.iconButtonSubmit' })}
            icon={searching ? <LoadingIcon width={16} height={16} /> : <SearchIcon width={16} height={16} />}
            onClick={(e) => onClickSubmitButton(e)}
          />
        </div>

        <IconButton
          className={'btn btn-text search-box--close flex'}
          type={'button'}
          icon={<CloseIcon width={16} height={16} />}
          ariaLabel={intl.formatMessage({ id: 'search.searchBox.buttonCloseMobile' })}
          onClick={() => {
            onCloseSearchMobile()
          }}
        />
      </form>
    </div>
  )
}

export default injectIntl(SearchBox)
