import React, { useCallback } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import { Hit } from 'react-instantsearch-core'
import { LabelIcon } from '../../atoms/icon'

export type Props = {
  searchResult: any,
  searchHitsOpen: boolean,
  valueText: any,
  onUpdateBrandUrl: (data: any) => void
} & WrappedComponentProps

const SearchBrandHits: React.FC<Props> = ({
  searchResult,
  searchHitsOpen,
  valueText,
  onUpdateBrandUrl,
}: Props) => {
  if (!searchResult) {
    return null
  }
  const { hits } = searchResult
  const { indexName } = searchResult
  const isDesktop = useDeviceType() === 'desktop'
  const REGEX_SPECIAL_URL = /[@!^&\\#,+()$~%.'":*?<>{}]/g

  const getBrandUrl = useCallback(() => {
    if (hits.length > 0 && valueText) {
      const urlBrand = hits.filter((hit) => hit.objectID && hit.objectID.toLowerCase().replace(REGEX_SPECIAL_URL, '') === valueText.trim().toLowerCase())
        .map((item) => item.url && item.url.replace(REGEX_SPECIAL_URL, ''))
      if (urlBrand && urlBrand.length && onUpdateBrandUrl) {
        onUpdateBrandUrl(urlBrand[0])
      }
    } else {
      // reset value if keyword change
      onUpdateBrandUrl('')
    }
  }, [hits, valueText])

  getBrandUrl()

  return (
    <>
      {hits.length > 0 && (
        <div className={classnames('search--brand search--common', {
          hidden: !searchHitsOpen,
        })}
        >
          {hits.length > 0 || (!isDesktop && hits.length === 0)}
          <h4 className={classnames('search-hits--headline', {
            block: !isDesktop && hits.length === 0,
          })}
          >
            <FormattedMessage id={'search.searchHits.brandHeadline'} />
          </h4>

          <div
            className={'search-hits--list search--brand-wrapper search--common-wrapper'}
            data-insights-index={indexName}
          >
            {hits.map((hit: Hit) => {
              const brandURL = () => {
                if (hit.url) {
                  const brandName = hit.url.replace(REGEX_SPECIAL_URL, '')
                  return `/${brandName}`
                }

                return null
              }

              return (
                <div
                  className={'relative search-brand-hit search-insights-hit'}
                  key={hit.objectID}
                  data-insights-object-id={hit.objectID}
                  data-insights-position={hit.__position}
                  data-insights-query-id={hit.__queryID}
                >
                  <a
                    href={brandURL()}
                    className={'search--brand-content'}
                  >

                    <div
                      className={'search--brand-content--item search--common-wrapper-content'}
                    >
                      <LabelIcon
                        width={20}
                        height={20}
                      />
                      <div
                        className={'search--brand-content--item-name search--common-wrapper-content-name'}
                      >
                        <span
                          className={'ais-Highlight'}
                          dangerouslySetInnerHTML={{ __html: hit._highlightResult.name.value }}
                        />
                      </div>
                    </div>
                  </a>
                  <div
                    className={'search--common-wrapper-category absolute right-0 lg:hidden'}
                  >
                    <FormattedMessage id={'search.searchHits.categoryMarken'} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default injectIntl(SearchBrandHits)
