import React, { Fragment, SetStateAction, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import CategoryService from '../../../services/category'

type Props = {
  searchResult: any
  setIsSearchCategoryEmpty: React.Dispatch<SetStateAction<boolean>>
  setSpecialCategoryUrl: any
  valueText?: string
  selectedCategoryOption?: any
  searchBrandUrl?: any
}

const RUNNING_CATEGORY_NAME = 'running'
const FUSSBALL_CATEGORY_NAME = 'fussball'

const SearchCategory: React.FC<Props> = ({
  searchResult,
  setIsSearchCategoryEmpty,
  setSpecialCategoryUrl,
  valueText,
  selectedCategoryOption,
  searchBrandUrl,
}) => {
  if (!searchResult) {
    return null
  }

  const [categoriesSuggestion, setCategoriesSuggestion] = useState([])

  useEffect(() => {
    const selectedCategoryName = selectedCategoryOption?.name?.toLowerCase()
    const regexRandomCategoryName = new RegExp(`> ${selectedCategoryName} >`, 'gi')
    const regexLastCategoryName = new RegExp(`> ${selectedCategoryName}$`, 'gi')
    const searchQuery = valueText && valueText.trim().toLowerCase()
    const hasSearchQueryInBrand = searchBrandUrl.length > 0 && searchBrandUrl.split('/').includes(searchQuery)
    const hasSearchQueryInCategory = (category) => category.url.toLowerCase().indexOf(searchQuery) !== -1
    const hasSearchQueryInSpecialCategory = (category, specialCategory) => category.url.toLowerCase().indexOf(specialCategory) !== -1
    const paths = []

    const getFacetCategories = !selectedCategoryName ? searchResult.facetHits
      : searchResult.facetHits?.filter((facetHit) => facetHit.value
        && (facetHit.value.toLowerCase().match(regexRandomCategoryName)
          || facetHit.value.toLowerCase().match(regexLastCategoryName)))

    if (!getFacetCategories?.length) {
      setCategoriesSuggestion([])
      setIsSearchCategoryEmpty(true)
      return
    }

    setIsSearchCategoryEmpty(false)
    getFacetCategories.forEach((item) => {
      item.highlightedArr = item.highlighted.split('> ')
      paths.push(item.value)
    })

    CategoryService.getUrlsByPath(paths).then((categories: any) => {
      getFacetCategories.forEach((facetCategory) => {
        const { url, isHiddenOnNav } = categories[facetCategory.value] || {}
        facetCategory.url = url || ''
        facetCategory.isHiddenOnNav = isHiddenOnNav
      })

      if (selectedCategoryName === RUNNING_CATEGORY_NAME && hasSearchQueryInBrand) {
        const brandCategoryName = categoriesSuggestion?.find((category) => category && hasSearchQueryInCategory(category)
          && hasSearchQueryInSpecialCategory(category, RUNNING_CATEGORY_NAME))
        return setSpecialCategoryUrl(brandCategoryName && brandCategoryName.url)
      }

      if (selectedCategoryName === FUSSBALL_CATEGORY_NAME && hasSearchQueryInBrand) {
        const brandCategoryName = categoriesSuggestion?.find((category) => category && hasSearchQueryInCategory(category)
          && hasSearchQueryInSpecialCategory(category, FUSSBALL_CATEGORY_NAME))
        return setSpecialCategoryUrl(brandCategoryName && brandCategoryName.url)
      }

      const getFirstCategory = categoriesSuggestion?.find((category) => category.isHiddenOnNav !== true)
      setSpecialCategoryUrl(getFirstCategory && getFirstCategory.url)
      setCategoriesSuggestion(getFacetCategories)
    })
  }, [searchResult])

  const renderCategory = () => {
    const hiddenCategory = ['Shop', 'Sportarten']

    const renderCategoryNameUI = () => (
      <div className={'search--common-wrapper-category absolute right-0 lg:hidden'}>
        <FormattedMessage id={'search.searchHits.categoryName'} />
      </div>
    )

    return categoriesSuggestion?.map((category) => {
      const highlightedCategories = category.highlightedArr.filter((hightlighItem) => !hiddenCategory.includes(hightlighItem.trim()))
      const lastHighLightedCategory = highlightedCategories.length - 1
      const lengOfHighlightedCategories = highlightedCategories.length

      if (category.isHiddenOnNav || !category.url) {
        return null
      }

      return (
        <div className={'relative'} key={category.url}>
          <a href={category.url}>
            {highlightedCategories.map((highlightedCategory, index) => {
              if (index > 0 && index < lastHighLightedCategory) {
                return null
              }

              return (
                <Fragment key={`highlightedCategory-${index}`}>
                  {lengOfHighlightedCategories > 2 && index === lastHighLightedCategory && ' /... / '}
                  {lengOfHighlightedCategories === 2 && index === lastHighLightedCategory && ' / '}
                  <span dangerouslySetInnerHTML={{ __html: highlightedCategory }} />
                </Fragment>
              )
            })}
          </a>
          {renderCategoryNameUI()}
        </div>
      )
    })
  }

  return (
    categoriesSuggestion.length > 0 && (
      <div className={'search--category search--layout'}>
        <h4 className={'search-hits--headline hidden lg:block'}>
          <FormattedMessage id={'search.searchHits.category'} />
        </h4>
        <div className={'search--category-content search--layout--content'}>
          {renderCategory()}
        </div>
      </div>
    )
  )
}

export default SearchCategory
