import React from 'react'
import PropTypes from 'prop-types'
import HeadlineComponent from '../../atoms/headline'
import Video from '../../atoms/video'

const Videos = ({
    text, tagHeadline, video, headlineColor,
}) => {
    const styleText = {}

    if (headlineColor) {
        styleText.color = headlineColor
    }

    return (
        <section className={'video-teaser my-comp'}>
            <HeadlineComponent
                tagHeadline={tagHeadline}
                text={text}
                styleHeadline={styleText}
            />
            <div className={'video-teaser__main md:flex'}>
                {video && video.map((item, index) => {
                    const { url, previewPic, title } = item
                    const key = url + index
                    return (
                        <Video
                            url={url}
                            key={key}
                            previewPic={previewPic}
                            title={title}
                        />
                    )
                })}
            </div>
        </section>
    )
}

Videos.propTypes = {
    text: PropTypes.string,
    headlineColor: PropTypes.string,
    tagHeadline: PropTypes.string,
    video: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        previewPic: PropTypes.object,
    })).isRequired,
}

export default Videos
