const isElementInViewport = (element) => {
    const rect = element.getBoundingClientRect()

    return (
        (rect.top <= (window.innerHeight || document.documentElement.clientHeight)
            && rect.left <= (window.innerWidth || document.documentElement.clientWidth))
        || (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            && rect.right <= (window.innerWidth || document.documentElement.clientWidth))
    )
}

export default isElementInViewport
